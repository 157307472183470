/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { init as aplitudeInit } from '@amplitude/analytics-browser'
// import * as Sentry from '@sentry/browser'
import type { Navigation } from '@sveltejs/kit'
import { once } from 'lodash-es'
import LogRocket from 'logrocket'
// import Plausible from 'plausible-tracker'
import type { apiOptions } from 'rudder-sdk-js'
import { rudderInitialize } from './analytics/rudder'

// import { captureException as captureBrowserException } from '@sentry/browser'

export const initTracking = once(() => {
	if (import.meta.env.DEV) return
	aplitudeInit('608aa57dcb96cb3b6425e46f6793aae2')
})

// const { trackEvent } = Plausible({
// 	domain: 'airheart.com',
// 	trackLocalhost: false,
// 	apiHost: 'https://data2.airheart.com/api/event',
// })

export async function trackRouteChange({ to }: Navigation) {
	if (import.meta.env.DEV) return

	try {
		const { page: rudderPage } = await rudderInitialize()
		rudderPage(to.url.toString())
	} catch (err) {
		console.error(err)
	}
}

export async function track(event: string, data: Record<string, string> = {}) {
	if (import.meta.env.DEV) {
		console.debug('track()', event, data)
		return
	}

	try {
		const { track: rudderTrack } = await rudderInitialize()
		rudderTrack(event, data)
	} catch (err) {
		reportError(err)
	}
}

export function identifyGoogleClickUser(url?: URL) {
	if (url?.searchParams?.has('uid')) {
		const uid = url.searchParams.get('uid')
		identify(undefined, {}, { anonymousId: uid })
	}
}

export async function identify(
	id?: string,
	traits: Record<string, any> = {},
	options: apiOptions = {},
) {
	if (import.meta.env.DEV) return

	try {
		const { identify: rudderIdentify } = await rudderInitialize()

		if (id == null) {
			rudderIdentify(undefined, traits, options as apiOptions)
			if (!isLoaded()) console.debug(`Disabled Segment Event: [identify]`, undefined, traits)
		} else {
			rudderIdentify(id, traits, options as apiOptions)

			if (!isLoaded()) console.debug(`Disabled Segment Event: [identify]`, id, traits)
		}
	} catch (err) {
		reportError(err)
	}

	try {
		if (traits?.email)
			gtag('set', 'user_data', {
				email: traits.email,
			})
	} catch (err) {
		reportError(err)
	}

	try {
		identifyOnSentry(id, { ...(traits || {}), ...(options || {}) })
	} catch (err) {
		reportError(err)
	}

	try {
		LogRocket.identify(id, { ...(traits || {}), ...(options || {}) })
	} catch (err) {
		reportError(err)
	}
}

function identifyOnSentry(id: string, traits: Record<string, any>) {
	if (import.meta.env.DEV) return

	try {
		// Sentry.setUser({ id, ...traits })
	} catch (err) {
		console.error(err)
	}
}

export function reportError(err: Error | string | unknown, data?: unknown) {
	try {
		// captureBrowserException(err, data)
	} catch (err) {
		console.error(err)
	}
	return err
}

export async function alias(to: string, from: string) {
	if (import.meta.env.DEV) return

	try {
		const { alias: rudderAlias } = await rudderInitialize()
		rudderAlias(to, from)
	} catch (err) {
		console.error(err)
	}
}

function isLoaded(): boolean {
	return true
}
